<template>
  <q-page>
    <div class="w100p bg-gris-panel q-px-lg q-py-sm row q-mb-md">
      <div class="col-8"><!-- q-pt-xs -->
        <div class="fs-17 fs-w-600">Usuarios</div>
        <q-breadcrumbs gutter="xs" class="mt-minus-2" active-color="grey-system">
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <q-breadcrumbs-el :key="i" :label="breadcrumb.name" :to="breadcrumb.to" :class="{'text-template': (i +1) === breadcrumbs.length}"/>
          </template>
        </q-breadcrumbs>
      </div>
      <div class="col-4 text-right row justify-end items-center">
        <q-btn label="Agregar usuario" @click="irAgregarUsuario()" color="primary"/>
      </div>
    </div>
    <q-scroll-area class="h100-125">
      <q-card class="w100p q-px-xl" flat>
        <q-card-section class="row filtros-section">
          <q-input class="w20p" dense outlined placeholder="Buscar palabra clave" ref="buscar"
                   :class="{'q-pb-xs q-mb-md': showFiltros}"
                   v-model="filtros.busqueda" @keyup.enter.prevent="getUsuarios()">
            <template #append>
              <q-icon name="las la-search" class="cursor-pointer" @click="getUsuarios()"/>
            </template>
          </q-input>
          <div class="w80p q-pl-md row items-start">
            <q-btn @click="limpiar()" color="gris-panel" icon="las la-sync" label="limpiar" unelevated
                   class="q-mr-md e-border-1" text-color="grey-system"/>
            <q-btn @click="cargarfiltros()" v-if="!showFiltros" class="e-border-1"
                   color="gris-panel" icon="las la-filter" text-color="grey-system" label="Filtros" unelevated/>
            <!-- SECCION PARA EL DISEÑO DEL FILTRO -->
            <q-tabs v-model="filtrosTab" indicator-color="orange-6" class="text-grey-system h50 filtros-border"
                    style="z-index:10"
                    dense v-if="showFiltros" inline-label active-bg-color="gris-panel" align="left">
              <q-tab name="filtros" icon="las la-filter" label="Filtros" content-class="align-content-top"
                     class="tab-indicator-top"/>
            </q-tabs>
          </div>
          <q-tab-panels v-model="filtrosTab" animated v-if="showFiltros"
                        class="bg-gris-panel border-bottom border-top w100p mt-minus-2">
            <q-tab-panel name="filtros" class="row pa-0 data-filtros">
              <div class="q-pa-md row col-12">
                <div class="col-3 q-px-lg">
                  <div class="mb4 fs-w-600">Usuario / Correo</div>
                  <q-input dense outlined v-model="filtros.busqueda" ref="second"/>
                </div>
                <div class="col-3 q-px-lg">
                  <div class="mb4 fs-w-600">Status</div>
                  <q-select :options="statusOpciones" dense emit-value multiple options-dense
                            outlined v-model="filtros.status" map-options>
                    <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
                      <q-item v-bind="itemProps" v-on="itemEvents">
                        <q-item-section>
                          <q-item-label v-html="opt.label"></q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-toggle :value="selected" @input="toggleOption(opt)"/>
                        </q-item-section>
                      </q-item>
                    </template>
                    <template #selected v-if="filtros.status.length === 0">Todos</template>
                  </q-select>
                </div>
                <div class="col-3 q-px-lg">
                  <div class="mb4 fs-w-600">Ordenamiento</div>
                  <q-select
                      :options="ordenOpciones"
                      dense
                      options-dense
                      emit-value
                      map-options
                      outlined
                      v-model="filtros.ordenar"/>
                </div>
                <div class="col-3 q-px-lg row">
                  <div class="mb4 col-12 fs-w-600">Fecha de Registro</div>
                  <div class="col-3 q-pt-xs">Desde:</div>
                  <q-field @click="$refs.fechaInicio.show()" dense outlined class="q-mb-sm col-9">
                    <template #append>
                      <q-icon name="las la-calendar-day"/>
                    </template>
                    <q-popup-proxy ref="fechaInicio" transition-hide="flip-left" transition-show="flip-right">
                      <q-date :options="fi => fi <= moment().format('YYYY/MM/DD')" @input="$refs.fechaInicio.hide()" minimal v-model="filtros.fechaInicio"/>
                    </q-popup-proxy>
                    <div class="self-center full-width no-outline text-grey-system" tabindex="0"
                         v-text="filtros.fechaInicio"/>
                  </q-field>
                  <div class="col-3 q-pt-xs">Hasta:</div>
                  <q-field @click="$refs.fechaFin.show()" dense outlined class="col-9">
                    <template #append>
                      <q-icon name="las la-calendar-day"/>
                    </template>
                    <q-popup-proxy ref="fechaFin" transition-hide="flip-left" transition-show="flip-right">
                      <q-date :options="ff => (ff >= moment(filtros.fechaInicio).format('YYYY/MM/DD') && ff <= moment().format('YYYY/MM/DD'))" @input="$refs.fechaFin.hide()" minimal v-model="filtros.fechaFin"/>
                    </q-popup-proxy>
                    <div class="self-center full-width no-outline text-grey-system" tabindex="0"
                         v-text="filtros.fechaFin"/>
                  </q-field>
                </div>
                <div class="col-12 text-right q-mt-lg">
                  <q-btn flat label="Cerrar" color="primary" class="q-mr-md" style="text-transform:none"
                         @click="cerrarFiltros()"/>
                  <q-btn unelevated label="aplicar filtros" color="gris-panel" text-color="grey-system"
                         class="e-border-1" @click="getUsuarios()"/>
                </div>
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </q-card-section>
        <q-card-section class="table-content">
          <div class="text-right mb4 q-mr-sm" v-text="registros"/>
          <q-table :columns="columnas"
                   :data="usuarios"
                   :rows-per-page-options="[0]"
                   :hide-bottom="usuarios.length > 0"
                   separator="cell" wrap-cells bordered
                   dense
                   table-header-class="table-content-th" table-class="table-content-rows"
                   class="header-fixed-table"
                   flat no-data-label="Sin registros encontrados"
                   style="max-height: calc(100vh - 230px)"
          >
            <template #body-cell-status="{row}">
              <q-td class="text-center">
                <q-badge :color="colorStatusBadge(row.status, 200)" :label="row.status_nombre"/>
              </q-td>
            </template>
            <template #body-cell-opciones="{row}">
              <q-td class="text-center">
                <q-icon name="las la-bars" color="grey-system" size="20px" class="q-ml-xs" style="cursor: pointer"/>
                <q-menu transition-show="flip-right" transition-hide="flip-left" anchor="bottom right"
                        self="top right" touch-position>
                  <q-list dense class="q-py-md w260">
                    <q-item clickable :to="{name: 'usuarioEditar', params: {id: row.usuario_id}}">
                      <q-item-section avatar>
                        <q-icon name="las la-edit" size="16px"/>
                      </q-item-section>
                      <q-item-section>Editar datos</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-td>
            </template>
          </q-table>
        </q-card-section>
      </q-card>
    </q-scroll-area>

  </q-page>
</template>

<script>
import {isEmpty, find, cloneDeep} from "lodash";
import UsuarioService from "../../Services/UsuarioServices";

const usuarioService = new UsuarioService();
export default {
  name: "usuariosGestor",
  data() {
    return {
      breadcrumbs: [{name: "Home", to: "/home"}, {name: "Usuarios"}],
      statusOpciones: [
        {label: "Suspendido", value: 100},
        {label: "Activo", value: 200},
        {label: "Baja", value: 300},
      ],
      filtros: {
        busqueda: this.$route.query.b || "",
        status: this.cargaFiltroArray('entero', [200]),
        fechaInicio: this.$route.query.fi || "",
        fechaFin: this.$route.query.ff || "",
        nivelDetalle: this.$route.query.nd || "gestor",
        ordenar: this.$route.query.o || "registro_fecha_desc",
        esSystem: false
      },
      usuarios: [],
      columnas: [
        {name: 'nombreCompleto', label: 'NOMBRE', align: 'left', field: 'nombre_completo', headerClasses: 'w18p'},
        {name: 'usuario', label: 'USUARIO', align: 'left', field: 'usuario', headerClasses: 'w13p'},
        {name: 'telefono', label: 'TÉLEFONO', align: 'center', field: 'telefono', headerClasses: 'w10p'},
        {name: 'correo', label: 'CORREO', align: 'left', field: 'correo_electronico', headerClasses: 'w15p'},
        {name: 'nombreCorto', label: 'NOMBRE CORTO', align: 'left', field: 'nombre_corto', headerClasses: 'w10p'},
        {name: 'status', label: 'STATUS', align: 'center', headerClasses: 'w8p'},
        {name: 'fechaRegistro', label: 'FECHA REGISTRO', align: 'center', field: 'registro_fecha', format: val => this.dateFormat(val, 2), headerClasses: 'w10p'},
        {name: 'autorRegistro', label: 'AUTOR REGISTRO', align: 'left', field: 'registro_autor', headerClasses: 'w8p'},
        {name: 'opciones', align: 'center', headerClasses: 'w5p'}
      ],
      modalAgregar: false,
      agregar: {
        serie: "",
        numeroControl: "",
        nombreComercial: "",
      },
      usuarioObj: {},
      showFiltros: false,
      filtrosTab: "filtros",
      ordenOpciones: [
        {label: "Nombre ascendente", value: "nombre_asc"},
        {label: "Nombre descendente", value: "nombre_desc"},
        {label: "Registro fecha ascendente", value: "registro_fecha_asc"},
        {label: "Registro fecha descendente", value: "registro_fecha_desc"},
      ],
    }
  },
  watch: {
  },
  computed: {
    registros() {
      let size = this.usuarios.length;
      return size + (size === 1 ? " registro" : " registros") + " encontrados";
    }
  },
  mounted() {    
    this.getUsuarios();
    this.$nextTick(() => this.$refs.buscar.focus());
  },
  methods: {
    isEmpty,
    cargarfiltros() {
      this.showFiltros = true;
      this.$nextTick(() => this.$refs.second.focus());
    },
    cargaFiltroArray(tipo = 'entero', arrayDefault = [200]){
      let routeStatus = this.$route.query.s;
      let result = [];

      if (!_.isEmpty(routeStatus)) {
        if (typeof(routeStatus) == 'string') {
          result.push(parseInt(routeStatus));
        }else{
          if (tipo == 'cadena') {
           result = routeStatus; 
          }else{
            routeStatus.forEach(elemento => {
              result.push(parseInt(elemento));
            });
          }
        }
      }else{
        result = arrayDefault;
      }
      
      return result;
    },
    cerrarFiltros() {
      this.showFiltros = false;
      this.filtros = {
        busqueda: "",
        status: [200],
        fechaInicio: "",
        fechaFin: "",
        nivelDetalle: "gestor",
        ordenar: "registro_fecha_desc",
      };
    },
    getUsuarios() {
      this.replaceUrl();

      this.loader(true);
      usuarioService.getUsuariosGestor(this.filtros).then(resp => {
        this.usuarios = resp;
      }).catch(error => this.alertShow(error, "error"))
          .then(() => this.loader(false));
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        status: [200],
        fechaInicio: "",
        fechaFin: "",
        nivelDetalle: "gestor",
        ordenar: "registro_fecha_desc",
        esSystem: false
      }
      this.replaceUrl();
      this.getUsuarios();
    },
    replaceUrl() {
      this.$router.replace({query: {}});
      let filtros = {
          b: this.filtros.busqueda,
          s: this.filtros.status,
          fi: this.filtros.fechaInicio,
          ff: this.filtros.fechaFin,
          nd: this.filtros.nivelDetalle,
          o: this.filtros.ordenar
      };
      this.$router.replace({query: filtros});
    },
    irAgregarUsuario() {
      this.$router.push({ name: "usuarioAgregar"});
    },
  }
}
</script>
