import axiosUtils from "../Services/Requests/AxiosUtils";
import axios      from "../Services/Requests/AxiosConfig";
import permisos   from "../permisos";

export default{
  //Metodo para listar registros
  listar(filtros) {
    return new Promise((resolve, reject) => {
      let params = {params: axiosUtils.getParams(filtros)};
      axios.get("usuarios/listar", params).then(resp => {
        let data = resp.data;
        if (data.codigo !== 200)
          throw data.mensaje;
        resolve(data.datos);
      }).catch(err => axiosUtils.axiosCatch(err, error => reject(error)));
    });
  },
  listarGestor(filtros) {
    return new Promise((resolve, reject) => {
      let params = {params: axiosUtils.getParams(filtros)};
      axios.get("usuarios/listarGestor", params).then(resp => {
        let data = resp.data;
        if (data.codigo !== 200)
          throw data.mensaje;
        resolve(data.datos);
      }).catch(err => axiosUtils.axiosCatch(err, error => reject(error)));
    });
  },
  agregarUsuario(datos) {
    return new Promise((resolve, reject) => {
      let parametros = axiosUtils.getPostParams(datos);
      parametros.append("permiso", permisos.USUARIOS_AGREGAR);
      
      axios.post("usuarios/agregarUsuario", parametros).then(resp => {
        let data = resp.data;
        if (data.codigo !== 200)
          throw data.mensaje;

        resolve(data);
      }).catch(error => axiosUtils.axiosCatch(error, () => reject(error)));
    });
  },
  obtenerUsuario(filtros) {
    return new Promise((resolve, reject) => {
      let params = {params: axiosUtils.getParams(filtros)};
      axios.get("usuarios/obtenerUsuario", params).then(resp => {
        let data = resp.data;
        if (data.codigo !== 200)
          throw data.mensaje;

        resolve(data.datos);
      }).catch(error => axiosUtils.axiosCatch(error, () => reject(error)));
    });
  },
  editarUsuario(datos) {
    return new Promise((resolve, reject) => {
      let parametros = axiosUtils.getPostParams(datos);
      parametros.append("permiso", permisos.USUARIOS_EDITAR);

      axios.post("usuarios/editarUsuario", parametros).then(resp => {
        let data = resp.data;
        if (data.codigo !== 200)
          throw data.mensaje;

        resolve(data);
      }).catch(error => axiosUtils.axiosCatch(error, () => reject(error)));
    });
  },

}